import { Button, Col, Form, Input, Row, Select, Spin, Space} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerUnix, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from "services";
import { ViewSMSList } from "../components/report.component"
import { enumToOptions, timeL2S, verify } from "utils/common";
import { selectFuzzyFilter } from 'utils/common';
import { SMS_ROLE } from 'constants/role';
import { SMS_STATE } from 'constants/state';
import { SiteCodeAndName } from "components/site.component";

// 短信紀錄

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [siteId, setSiteId] = useState<any>()
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    ownerRole: 0,
    startTime: timeL2S(date[0]),
    endTime: timeL2S(date[1]),
  })

  // 站台簡訊紀錄列表
  const { data, mutate, isValidating } = $get({
    url: `admin/sms-log/sites/${siteId}/logs`,
    params: {
      ...params,
      page: page[0],
      rows: page[1]
    },
    allow: !!siteId,
  })

  // 取得站台列表
  const { data: siteList } = $get({
    url: 'admin/sms-log/sites'
  })

  // 站台簡訊廠商列表
  const { data: merchantList } = $get({
    url: `admin/sms-log/sites/${siteId}/merchants`,
    allow: !!siteId
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        merchantCode: formData.Merchant,
        ownerRole: formData.OwnerRole,
        account: formData.Account,
        phonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
        phoneNumber: formData.PhoneNumber,
        status: formData.Status,
        startTime: timeL2S(date[0]),
        endTime: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form form={form} onFinish={onFinish} initialValues={{
          OwnerRole: '',
        }}>
          {/* 搜尋列 */}
          <Row align="top" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="site" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                <SiteCodeAndName name="site" form={form} apiUrl={'admin/sms-log/sites'} setSiteId={setSiteId} className="w-12"/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Merchant">
                <Select
                  disabled={!siteId}
                  placeholder={i18n.t('SmsMerchantCode')}
                  options={[
                  { value: '', label: `${i18n.t('ALL')}` },
                  ...(merchantList?.Data.map((item: any) => (
                    { value: item.Code, label: item.Name }
                  )) || [])
                ]}/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="OwnerRole">
                <Select
                  disabled={!siteId}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(SMS_ROLE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t('account')}`} disabled={!siteId}/>
              </Form.Item>
            </Col>
            <Col >
              <Row>
                <Space.Compact >
                  <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })} >
                    <Input autoComplete="off" placeholder={`${i18n.t('countryCode')}`} addonBefore={'+'} disabled={!siteId} className="w-8"/>
                  </Form.Item>
                  <Form.Item name="PhoneNumber" >
                    <Input placeholder={`${i18n.t('phoneNumber')}`} disabled={!siteId}/>
                  </Form.Item>
                </Space.Compact>
              </Row>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Status">
                <Select
                  disabled={!siteId}
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(SMS_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <DatePickerUnix width={191} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button htmlType="submit" type="primary">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          {/* 搜尋結果 */}
          <Row className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewSMSList data={data} isHaveSiteId={!!siteId} siteId={siteId}/>
                <LayoutPagination total={data?.Total} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;