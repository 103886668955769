import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Cookies from 'js-cookie';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE } from "constants/response";
import i18n from "i18n";
import { message } from "antd";
import React, { useEffect } from "react";

export const CKEditorComp = ({ data, setData, form, fieldName, image, disabled }: any) => {

  class MyUploadAdapter {
    public loader: any;
    public token: any;

    constructor(loader: any) {
      this.loader = loader;
      this.token = Cookies.get(COOKIE.TOKEN);
    }

    upload() {
      return new Promise(async (resolve, reject) => {
        this.loader.file.then((file: any) => {
          this._sendRequest(resolve, reject, file);
        });
      });
    }

    _sendRequest(resolve: any, reject: any, file: any) {

      const newFormData = new FormData();
      newFormData.append('Media', file as any);

      const upload = fetch('admin/upload/images', {
        headers: {
          "Authorization": this.token
        },
        method: 'POST',
        body: newFormData,
      })
        .then(res => {
          const response: any = res.json();
          if (res.ok) return response;
          else throw response;
        })
        .then(data => {
          // 成功
          if (data.State === 'Success') {
            resolve({
              default: data.Data[0],
            });
          } else {
            const msg = RESPONSE_CODE[data.Message as keyof typeof RESPONSE_CODE];
            message.error(msg || i18n.t('processingFailed'));
            reject();
          };
        })

    }
  }

  function CustomImageUploadAdapter(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new MyUploadAdapter(loader);
  };

  const CKdefaultConfig = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        image ? 'imageUpload' : '',
        'insertTable',
        'blockQuote',
        '|',
        'bulletedList',
        'numberedList',
      ]
    },
    image: {
      toolbar: [
        'insertImage',
        // 'imageStyle:full',
        // 'imageStyle:side',
        // '|',
        // 'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: 'en'
  }

  useEffect(() => {
    if (form) form.setFieldValue(fieldName, data);
  }, []);

  return (
    <CKEditor
      disabled={disabled}
      data={data}
      editor={ClassicEditor as any}
      config={{
        extraPlugins: [CustomImageUploadAdapter],
        toolbar: CKdefaultConfig.toolbar,
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
      }}
      onBlur={(event, editor) => {
        const data = editor.getData();
        setData(data);
        if (form) form.setFieldValue(fieldName, data);
      }}
    />
  )
}