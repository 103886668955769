import { Button, Col, Form, Image, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table } from 'antd';
import { UploadFile } from 'antd/lib';
import { useForm } from 'antd/lib/form/Form';
import hintIcon from 'assets/image/icon-12.png';
import systemDefaultGameIcon from 'assets/image/system defalt_Game icon.png';
import { RESPONSE_CODE, RESPONSE_CODE_CREATE_ANNOUNCEMENT, RESPONSE_CODE_GAME } from 'constants/response';
import { ENABLE } from "constants/state";
import useAccount from 'hooks/account.hook';
import useLanguage from 'hooks/language.hook';
import { RootType, setSelectSiteId } from 'hooks/store.hook';
import i18n from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { enumToOptions, gameNameTransfer, timeS2L, verify } from "utils/common";
import { LayoutTabPlatform, UploadImage } from './layout.component';
import { $api, $delete, $patch, $post, $put } from 'services';
import React, { useEffect, useState } from 'react';
import { SiteCodeAndName } from './site.component';

// 新增權限
export const PopupAddPermission = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = (formData: any) => {
    $post({
      url: 'admin/permission/roles',
      send: {
        name: formData.name,
        description: formData.description,
        isEnabled: formData.isEnabled === ENABLE.enabled
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      }
    })
  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      forceRender
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} maxLength={100} showCount />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={i18n.t('description')}
              rules={[{ type: 'string', max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="isEnabled" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-12"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" className="mt-1" gutter={[12, 12]}>
              <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 新增後台帳號
export const PopupAddBackOfficeAccount = ({ isOpen, close, mutate, permissionList }: any) => {
  const [form] = useForm();

  const onFinish = (formData: any) => {
    if (formData.password !== formData.confirmPassword) {
      return message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'))
    }
    $post({
      url: 'admin/permission/users',
      send: {
        account: formData.account,
        name: formData.name,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        description: formData.description,
        roleId: `${formData.roleId}`,
        isEnabled: true
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      // resCode: RESPONSE_CODE_PERMISSION
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      width={450}
      onCancel={onClose}
      centered
      forceRender
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>

          {/* 帳號 */}
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('backOfficeAccount')}
              rules={[...verify({ point: 5 }), { type: 'string', min: 4, max: 12 }]} >
              <Input placeholder={`${i18n.t('requiresEnglishAndNumbers')}`} />
            </Form.Item>
          </Col>

          {/* 密碼 */}
          <Col span={24}>
            <Form.Item name="password" label={i18n.t('password')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="confirmPassword" label={i18n.t('confirmPassword')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={i18n.t('description')}
              rules={[{ type: 'string', max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="roleId" label={i18n.t('selectPermissions')} rules={[{ required: true }]}>
              <Select
                className="w-12"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={permissionList?.Data?.map((item: any) => ({
                  value: item.id, label: item.name
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} maxLength={10} showCount />
            </Form.Item>
          </Col>

          {/* 確認取消 */}
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]} className="mt-1 mb-1">
              <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupEditBackOfficeAccount = ({ isOpen, close, record, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (record && isOpen) {
      form.setFieldsValue({
        account: record.account,
        isEnabled: record.isEnabled ? ENABLE.enabled : ENABLE.disabled,
        description: record?.profile.description,
        name: record?.profile.name,
      })
    }
  }, [record, isOpen])

  const onFinish = (formData: any) => {
    if (record?.id) {
      $put({
        url: `admin/permission/users/${record?.id}`,
        send: {
          isEnabled: !!formData.isEnabled,
          description: formData.description,
          name: formData.name
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        }
      })
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      forceRender
      footer={false}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row justify="center" gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} maxLength={10} showCount />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={i18n.t('description')}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="isEnabled" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-12"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]} className="mt-1">
              <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 變更密碼
export const PopupBackOfficeAccountPassword = ({ isOpen, close, record, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (record && isOpen) {
      form.setFieldsValue({
        account: record.account,
      })
    }
  }, [record, isOpen])

  const onFinish = (formData: any) => {
    if (formData.password !== formData.confirmPassword) {
      return message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'))
    }
    if (record?.id) {
      $patch({
        url: `admin/permission/users/${record.id}/password`,
        send: {
          password: formData.password,
          confirmPassword: formData.confirmPassword,
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          onCancel();
          mutate();
        },
        // resCode: RESPONSE_CODE_PERMISSION
      })
    }
  }

  const onCancel = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('changePassword')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={450}
      forceRender
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row justify="center" gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="password" label={i18n.t('password')} rules={
              [...verify({ mustMeetPasswordFormat: true })
            ]}>
              <Input.Password placeholder={`${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="confirmPassword" label={i18n.t('confirmNewPassword')} rules={
              [...verify({ mustMeetPasswordFormat: true })
              ]}>
              <Input.Password placeholder={`${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col>
            <Row justify="center" gutter={[12, 12]} className="mt-1">
              <Col><Button key="cancel" onClick={onCancel}>{i18n.t('cancel')}</Button></Col>
              <Col><Button htmlType="submit" key="confirm" type="primary">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupBatchUpdate = ({
  isOpen,
  close,
  selectedRowKeys,
  setSelectedRowKeys,
  batchUpdateValue,
  setBatchUpdateValue,
  mutate,
  siteId
}: {
  isOpen: boolean;
  close: () => void;
  selectedRowKeys: any;
  setSelectedRowKeys: any;
  batchUpdateValue: any;
  setBatchUpdateValue: any;
  mutate: () => void;
  siteId: any
}) => {
  // 批次更新狀態
  const onBatchUpdate = () => {
    $post({
      url: 'admin/game/update',
      send: {
        Ids: selectedRowKeys,
        IsOpen: batchUpdateValue,
        siteId: siteId
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        onClose();
      }
    })
  }

  const onClose = () => {
    close();
    mutate();
    setSelectedRowKeys([]);
    setBatchUpdateValue(null);
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]} >
          <Col>
            <Button className="mt-1" onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary" onClick={onBatchUpdate}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>
          {i18n.t('confirmationBatch')} {batchUpdateValue ? i18n.t('release') : i18n.t('disable')} {selectedRowKeys && selectedRowKeys.length} {i18n.t('counts')}{i18n.t('isData')}?
        </div>
      </Space>
    </Modal>
  )
}

export const ViewGameList = ({ data, mutate, selectedRowKeys, setSelectedRowKeys, siteId, GameUrl }: {
  data: any;
  mutate: () => void;
  selectedRowKeys: any;
  setSelectedRowKeys: any;
  siteId: any;
  GameUrl: any;
}) => {
  const { permissions: $p } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    if (GameUrl) {
      setUrl(GameUrl);
    }
  }, [GameUrl]);
  // 更新單筆遊戲狀態
  const handleEditStatus = (Id: number, state: boolean) => {
    $post({
      url: 'admin/game/update',
      send: {
        Ids: [Id],
        IsOpen: state,
        siteId: siteId
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_GAME
    }, setIsLoading)
  }

  const { getLang: lang } = useLanguage();

  return (
    <>
      <Table
        size="small"
        scroll={{ x: 1500 }}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Id',
            title: i18n.t('ID'),
            width: 300,
            fixed: 'left',
          },
          {
            dataIndex: 'Code',
            title: i18n.t('gameCode'),
            width: 200,
          },
          {
            dataIndex: 'CategoryCode',
            title: i18n.t('gameCategory'),
            width: 200,
            render: (val) => i18n.t(val)
          },
          {
            dataIndex: 'Lang',
            title: i18n.t('gameName'),
            width: 350,
            render: (val, { Name }) => gameNameTransfer(val, Name, lang)
          },
          {
            dataIndex: 'Photo',
            title: i18n.t('Logo'),
            width: 200,
            render: (_, { ProviderCode, Code }) => {
              const GameLogo = lang ? `${url}${lang}/${ProviderCode}/${Code}.png` : ''
              return (
                <Image src={GameLogo} fallback={systemDefaultGameIcon} width={50} height={50} />
              )
            }
          },
          {
            dataIndex: 'IsOpen',
            title: i18n.t('shelvesDelisting'),
            width: 150,
            render: (val, record) =>
              <Switch checked={val} loading={isLoading} disabled={!$p('game.modifyCCGame')}
                onChange={v => handleEditStatus(record.key, v)} />
          },
          {
            dataIndex: 'CreateDateTime',
            title: i18n.t('createTime'),
            width: 200,
            render: (val: string) => timeS2L(val)
          },
        ]}
        pagination={false}
      />
    </>
  )
}

export const PopupDeleteAnnouncement = ({ isOpen, close, id, mutate }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    $delete({
      url: `admin/system-announcement/announcements/${id}`,
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    }, setIsLoading)
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary"
              onClick={handleDelete} loading={isLoading}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
};

export const PlatformLayout = ({ tabKey, setOption, siteList }: {
  tabKey: string;
  setOption?: any;
  siteList?: any;
}) => {

  const dispatch = useDispatch();
  const siteId = useSelector((state: RootType) => state.Select.siteId);
  const [form] = useForm();

  const onFinish = ({ Option }: {
    Option: any
  }) => {
    setOption(Option);
  };

  useEffect(() => {
    if (siteId && siteList) {
      form.setFieldsValue({
        Option: siteId
      });
    }
  }, [siteId, siteList]);

  const handleSelectChange = (e: any) => {
    dispatch(setSelectSiteId({
      siteId: e
    }))
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col>
              <Form.Item name="Option" className="w-12" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                <SiteCodeAndName name="Option" form={form} apiUrl={'admin/site-data/sites'} handleChangeFunction={handleSelectChange} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {!!siteId &&
          <Col span={24}>
            <LayoutTabPlatform activeKey={tabKey} />
          </Col>
        }
      </Row>
    </Form>
  )
};

export const PopupAddLicense = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const siteId = useSelector((state: RootType) => state.Select.siteId);

  const onFinish = (formData: any) => {
    $post({
      url: `admin/license/sites/${siteId}/licenses`,
      send: {
        title: formData.title,
        photo: imageData[0] ? imageData[0].url : '',
        linkURL: formData.link,
        position: formData.sort,
        status: formData.status
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE
    }, setLoading);
  };

  const onClose = () => {
    close();
    setImageData([]);
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('add')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}
        initialValues={{
          status: 1,
        }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`100${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="photo" valuePropName="fileList" label={i18n.t('image')}
              rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
              <UploadImage
                name="photo"
                url={'admin/license/image'}
                imageData={imageData}
                setImageData={setImageData}
                submitLoading={setLoading}
                form={form}
                w={140}
                h={50}
              />
            </Form.Item>
            <div className="color-03 size-12">
              {i18n.t('onlyJpgOrPngFiles')}
              <span className="require">{i18n.t('imageMobileVersionWidth140pxHeight50px')}</span>
              {i18n.t('allowedAndSizeNotExceed500kb')}
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')}
              rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export const PopupEditLicense = ({ isOpen, close, data, mutate }: {
  isOpen: boolean;
  close: () => void;
  data: {
    key: number;
    Lang: string;
    Position: number;
    Title: string;
    photo_1: string;
    LinkURL: string;
    Status: number;
  }
  mutate: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const siteId = useSelector((state: RootType) => state.Select.siteId);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        title: data.Title,
        photo: data.photo_1,
        link: data.LinkURL,
        sort: data.Position,
        status: data.Status
      })
      setImageData([
        {
          uid: data.photo_1,
          name: data.photo_1,
          url: data.photo_1,
        },
      ])
    }
  }, [data, isOpen]);

  const onFinish = (formData: any) => {
    $put({
      url: `admin/license/sites/${siteId}/licenses`,
      send: {
        id: data.key,
        title: formData.title,
        photo: imageData ? imageData[0].url : data.photo_1,
        linkURL: formData.link,
        position: formData.sort,
        status: formData.status
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE
    }, setLoading);
  };

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  };

  return (
    <Modal
      title={i18n.t('edit')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`100${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="photo" valuePropName="fileList" label={i18n.t('image')}
              rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]} required>
              <UploadImage
                name="photo"
                url={'admin/license/image'}
                preUrl={data ? data.photo_1 : ''}
                imageData={imageData}
                setImageData={setImageData}
                submitLoading={setLoading}
                form={form}
                w={140}
                h={50}
              />
            </Form.Item>
            <div className="color-03">
              {i18n.t('onlyJpgOrPngFiles')}
              <span className="require">{i18n.t('imageMobileVersionWidth140pxHeight50px')}</span>
              {i18n.t('allowedAndSizeNotExceed500kb')}
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')}
              rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export const PopupDeleteLicense = ({ isOpen, close, id, mutate }: any) => {
  const [loading, setLoading] = useState(false);
  const siteId = useSelector((state: RootType) => state.Select.siteId);

  const onDelete = () => {
    $delete({
      url: `admin/license/sites/${siteId}/licenses`,
      send: { id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        close();
        mutate();
      },
      resCode: RESPONSE_CODE
    }, setLoading);
  };

  const onClose = () => {
    close();
  };

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary" onClick={onDelete} loading={loading}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
};