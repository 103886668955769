import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  text: {
    bankName: '',
    bankDisplayName: '',
  },
  siteId: ''
}

// 依據需求建立不同Slice
const searchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {
    setSearchHistory: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
})

const siteIdSlice = createSlice({
  name: 'siteId',
  initialState,
  reducers: {
    setSelectSiteId: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
})

const apiTimestamp = createSlice({
  name: 'apiTimestamp',
  initialState: {

  },
  reducers: {
    setTimestamp: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
})

// 將多個不同Slice函數組合成單一的 reducer 函數
const rootReducers = combineReducers({
  Search: searchSlice.reducer,
  Select: siteIdSlice.reducer,
  Timestamp: apiTimestamp.reducer,
});
export type RootType = ReturnType<typeof rootReducers>

// store相關
const store = configureStore({
  reducer: rootReducers
})
export default store;

// 匯出不同Slice的dispatch相關函數
export const { setSearchHistory } = searchSlice.actions;
export const { setSelectSiteId } = siteIdSlice.actions;
export const { setTimestamp } = apiTimestamp.actions;