import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutPagination, DatePickerUnix } from 'components/layout.component';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { $get } from "services";
import i18n from 'i18n';
import {MEMBER_FREEZE, MEMBER_STATE} from 'constants/state'
import { enumToOptions, timeL2S } from "utils/common";
import { MemberLevelSelect, ViewMemberList } from 'components/member.component';
import { SiteCodeAndName } from 'components/site.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootType, setSearchHistory, setSelectSiteId } from 'hooks/store.hook';

// 會員列表

enum SEARCH_TYPE {
  phoneNumber = 1,
  email = 2,
  realName = 3,
  idNum = 4
}

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [reset, setReset] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<any>(useSelector((state: RootType) => state.Select.siteId) || null)
  const defaultDate: number = 2;
  const [timeMode, setTimeMode] = useState<number>(defaultDate);
  const [triggerApi, setTriggerApi] = useState(true);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [memberSearchParam, SetMemberSearchParam] = useState<any>({
    type: 0,
    page: 1,
    rows: 10,
  });

  // 搜尋儲存
  const searchMember = useSelector((state: RootType) => state.Search.member);

  // 取得會員列表
  const { data, mutate, isValidating } = $get({
    url: `admin/member/sites/${siteId}/members`,
    params: searchMember || memberSearchParam,
    allow: !!siteId && triggerApi
  });

  // 先留著之後首頁連結過來會用到
  // useEffect(() => {
  //   if (location.search) {
  //     const isOnline = Number(location.search.split('')[location.search.length - 1]);
  //     form.setFieldsValue({
  //       onlineType: isOnline,
  //       timeMode: 2
  //     });
  //     setTimeMode(2);
  //     SetMemberSearchParam({
  //       page: 1,
  //       rows: 10,
  //       type: 0,
  //       isOnline,
  //     })
  //   }
  // }, [location.search]);

  const handleSearch = (formData: any) => {
    const obj: any = {
      page: 1,
      rows: memberSearchParam.rows,
      type: 0,
      account: formData.account,
      refCode: formData.referralCode,
      isOnline: formData.onlineType,
      upMemberAccount: formData.recommendedMembers,
      agAccount: formData.agentAccount,
      saAccount: formData.masterAgent,
      status: formData.status,
      isFreeze: formData.IsFreeze ? (formData.IsFreeze === MEMBER_FREEZE.normal ? 0 : 1) : '',
      levelId: formData.LevelId
    }
    switch (formData.searchType) {
      case 1:
        obj.phoneNumber = formData.searchData;
        break;
      case 2:
        obj.email = formData.searchData;
        break;
      case 3:
        obj.name = formData.searchData;
        break;
      case 4:
        obj.idNumber = formData.searchData;
        break;
    }

    switch (formData.timeMode) {
      case 0:
        obj.lastLoginStartDate = timeL2S(date[0] as string)
        obj.lastLoginEndDate =timeL2S(date[1] as string)
        break;
      case 1:
        obj.createStartDate = timeL2S(date[0] as string)
        obj.createEndDate = timeL2S(date[1] as string)
        break;
    }

    SetMemberSearchParam(obj);
    dispatch(setSearchHistory({ member: obj }));
    mutate();

    setTriggerApi(true);
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    SetMemberSearchParam({
      ...memberSearchParam,
      rows: 10,
      page: 1,
    })
  };

  const onSelectSite = (e: any) => {
    setSiteId(e);
    // setTriggerApi(false);

    dispatch(setSelectSiteId({
      siteId: e
    }))
  }

  const handlePageChange = ([current, pageSize]: any) => {
    const param = searchMember || memberSearchParam
    SetMemberSearchParam({ ...param, page: current, rows: pageSize })
    dispatch(setSearchHistory({ member: { ...param, page: current, rows: pageSize } }));
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        <Form form={form} onFinish={handleSearch} initialValues={{
          timeMode: 2,
          searchType: 1,
          siteId: siteId
        }}>
          <Row align="middle" gutter={[12, 12]}>
            {/* 三排搜尋 */}
            <Col span={24}>
              <Row align="top" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="siteId" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                    <SiteCodeAndName name="siteId" form={form} apiUrl={'admin/member/sites'} setSiteId={setSiteId} handleChangeFunction={onSelectSite} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="account">
                    <Input placeholder={`${i18n.t('memberAccount')}`} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="IsFreeze">
                    <Select
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(MEMBER_FREEZE)
                      ]}
                      disabled={!siteId}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="status">
                    <Select
                      placeholder={i18n.t('audit')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(MEMBER_STATE)
                      ]}
                      disabled={!siteId}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId" >
                    <MemberLevelSelect name="LevelId" form={form} platformId={siteId} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchType">
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onSelect={() => form.resetFields(['searchData'])}
                      options={enumToOptions(SEARCH_TYPE)}
                      disabled={!siteId}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchData">
                    <Input placeholder={`${i18n.t('inputData')}`}
                           disabled={!siteId}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="referralCode">
                    <Input placeholder={`${i18n.t('promotionCode')}`} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="recommendedMembers">
                    <Input placeholder={`${i18n.t('recommendedMembers')}`} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="agentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="masterAgent">
                    <Input placeholder={`${i18n.t('masterAgent')}`} disabled={!siteId}/>
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="onlineType">
                    <Select
                      placeholder={i18n.t('onlineStatus')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        { value: 1, label: i18n.t('Online') },
                        { value: 0, label: i18n.t('Offline') },
                      ]}
                      disabled={!siteId}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="timeMode">
                    <Select
                      options={[
                        { value: 0, label: `${i18n.t('lastLoginTime')}` },
                        { value: 1, label: `${i18n.t('createTime')}` },
                        { value: 2, label: `${i18n.t('noTimeLimit')}` },
                      ]}
                      onChange={setTimeMode}
                      disabled={!siteId}
                    />
                  </Form.Item>
                </Col>
                <DatePickerUnix width={180} date={date} setDate={setDate} initDate={reset} displayCom={['Picker']} disabled={timeMode === 2} />
                <Col>
                  <Button type="primary" htmlType="submit" className="mr-1">{i18n.t('search')}</Button>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <ViewMemberList data={data} mutate={mutate} searchParam={memberSearchParam} isValidating={isValidating} siteId={siteId} triggerApi={triggerApi}/>
          </Row>
          <LayoutPagination total={data?.Total} setPage={handlePageChange}
            page={[(searchMember || memberSearchParam).page, (searchMember || memberSearchParam).rows]} />
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;