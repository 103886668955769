import { Col, message, Row, Switch, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PlatformLayout } from 'components/advanced.component';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE } from 'constants/response';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import { RootType } from 'hooks/store.hook';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { $get, $patch } from "services";
import { specialProviderName, timeS2L } from 'utils/common';

// 站台設置: 遊戲資料

interface ProviderInfoType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
};

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { data: site } = useSite();
  const [form] = useForm();
  const siteId = useSelector((state: RootType) => state.Select.siteId);
  const { data: ProviderInfo, mutate, isValidating } = $get({ url: `admin/game-data/sites/${siteId}/providers`, allow: !!siteId });

  // 取得站台列表
  const { data: siteList } = $get({ url: 'admin/site-data/sites' });

  useEffect(() => {
    if (siteId) {
      form.setFieldsValue({
        Option: siteId
      });
    }
  }, [siteId]);

  const handleStatus = (value: boolean, id: number) => {
    const Status = value ? 1 : 0;
    $patch({
      url: `admin/game-data/sites/${siteId}/providers/status`,
      send: { id, isOpen: Status },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE
    });
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <PlatformLayout tabKey={'2'} siteList={siteList} />
        {!!siteId && 
        <Row align="middle" gutter={[12, 12]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              scroll={{ x: 1500 }}
              dataSource={ProviderInfo?.Data.map((item: ProviderInfoType) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  dataIndex: 'ProviderName',
                  title: i18n.t('gameProvider'),
                  width: 250,
                  fixed: 'left',
                  render: (val, { ProviderCode }) => specialProviderName(val, site.SiteName, ProviderCode)
                },
                {
                  dataIndex: 'CategoryCode',
                  title: i18n.t('gameCategory'),
                  width: 250,
                  render: (val) => i18n.t(val)
                },
                {
                  dataIndex: 'IsOpen',
                  title: i18n.t('gameStatus'),
                  width: 350,
                  render: (val, { Id }) =>
                    <Switch
                      disabled={!$p('gameData.updateProviderOpen')}
                      checked={val} 
                      defaultChecked={val} 
                      onChange={v => handleStatus(v, Id)} />
                },
                {
                  dataIndex: 'Account',
                  title: `${i18n.t('platform')}${i18n.t('updater')}`,
                  width: 300,
                  render: (val) => val || 'System'
                },
                {
                  dataIndex: 'UpdateDate',
                  title: i18n.t('updateTime'),
                  width: 300,
                  render: (val: string) => timeS2L(val)
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>}
      </Content>
    </div>
  );
};

export default PageMain;