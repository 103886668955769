import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import type { ActionFunctionArgs } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import PageAdvancedAccount from "pages/account.page";
import PageAdvancedAddSystemAnnouncement from "pages/announcement-add.page";
import PageAdvancedEditSystemAnnouncement from "pages/announcement-edit.page";
import PageAdvancedAnnouncement from "pages/announcement.page";
import PageAdvancedGameData from "pages/game-data.page";
import PageAdvancedGameList from "pages/game-list.page";
import PageAdvancedGameManagement from "pages/game-management.page";
import PageAdvancedGameProviderRebate from "pages/game-provider-rebate.page";
import PageAdvancedGameProviderSort from "pages/game-provider-sort.page";
import PageAdvancedLoginRecord from "pages/login-record.page";
import PageAdvancedPermission from "pages/permission.page";
import PageAdvancedPermissionEdit from "pages/permission-edit.page";
import PageAdvancedLicense from "pages/license.page";

async function loader() {
  try {

    return {
      test: {}
    };

  } catch (e) {
    throw new Error("無法取得loader資料");
  };
};

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  switch (formData.get("action")) {
    case "add":
      break;
    case "delete":
      break;

    default:
      break;
  }

  return { ok: true };
}

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const advancedModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/advanced/platform-game-data', Component: PageAdvancedGameData },
      { path: '/advanced/platform-provider-sort', Component: PageAdvancedGameProviderSort },
      { path: '/advanced/platform-game-list', Component: PageAdvancedGameList },
      { path: '/advanced/platform-game-rebate', Component: PageAdvancedGameProviderRebate },
      { path: '/advanced/platform-license', Component: PageAdvancedLicense },
      { path: '/advanced/permission', Component: PageAdvancedPermission },
      { path: '/advanced/permission/edit/:id', Component: PageAdvancedPermissionEdit },
      { path: '/advanced/account/:id?', Component: PageAdvancedAccount },
      { path: '/advanced/announcement', Component: PageAdvancedAnnouncement },
      { path: '/advanced/game-management', Component: PageAdvancedGameManagement },
      { path: '/advanced/login-record', Component: PageAdvancedLoginRecord },
      { path: '/advanced/announcement/system/add', Component: PageAdvancedAddSystemAnnouncement },
      { path: '/advanced/announcement/system/edit/:id', Component: PageAdvancedEditSystemAnnouncement },
    ],
  },
]