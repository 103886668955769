import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import React from 'react';

// 營運報表

const PageMain: React.FC = () => {

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        營運報表
      </Content>
    </div >
  );
};

export default PageMain;