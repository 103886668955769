import { message } from "antd";
import { COOKIE } from "constants/cookie";
import { RESPONSE_CODE } from "constants/response";
import i18n from "i18n";
import Cookies from "js-cookie";
import QueryString from 'qs';
import useSWR from 'swr';
import userLoginLog from './user-login-log.service';
import license from './license.service';

const mockList: any = {
  "user-login-log": userLoginLog,
  "license": license
};

interface ApiOptions {
  url: string;
  send?: Record<string, any>; 
  allow?: boolean;
  success?: (data: any) => void; 
  fail?: (data: any) => void;
  showMessage?: boolean; 
  resCode?: Record<string, any>; 
  auth?: boolean;
  queryHeader?: boolean;
}

interface GetResponse {
  Data: any;
  Page?: string | number | null;
  Rows?: string | number | null;
  Total?: string | number | null;
  Headers: Record<string, any>;
  Timestamp: number;
}

const requestInformation = (data: any) => {
  const query = QueryString.stringify(data, { addQueryPrefix: true });
  const token = Cookies.get(COOKIE.TOKEN);
  const headers: any = {
    'Content-Type': 'application/json'
  }

  if (token) headers.Authorization = `${token}`;

  return {
    query,
    headers,
    token
  }
}

// 回應狀態碼判斷
export const responseProcessing = async (response: any) => {
  const body = await response.text();
  let res: any = {};

  if (response.status === 401) {
    res = { errorCode: 'unauthorized' }
  } 
  else if (body.includes('errorCode') || (
    response.status !== 404 &&
    response.status !== 400
  )) {
    res = body ? JSON.parse(body) : {};
  } 
  else {
    res = { errorCode: 'fail' }
  }

  return res;
}

// 錯誤訊息及處理
const errorAlert = (errorCode: string, resCode: any) => {

  // 沒有權限
  if (errorCode === 'forbidden') {
    window.location.replace('/#/');
  }
  else if (errorCode === 'unauthorized') {
    window.location.replace('/#/login/UNAUTHORIZATION');
    Cookies.remove(COOKIE.TOKEN);
    return;
  } 
  else if (errorCode === 'accountIsDisabled' || errorCode === 'accountRoleIsDisabled') {
    if (window.location.hash.includes('#/login')) {
      message.error(RESPONSE_CODE['ACCOUNT_BLOCKED']);
      return;
    } else {
      window.location.replace('/#/login/ACCOUNT_BLOCKED');
      Cookies.remove(COOKIE.TOKEN);
      return;
    }
  }

  const codeI18n: any = {
    ...RESPONSE_CODE,
    ...resCode
  }

  message.error(codeI18n[errorCode] || i18n.t('incorrectRequest'));
}

// 遞迴找 mock function
const searchMockList: any = async (layer: any, phaseArray: any, name: string) => {
  const i = phaseArray.indexOf(name || phaseArray[1]);
  // 最後一層了 回應資料
  if (i === phaseArray.length - 1)  {
    return layer[name]();
  }
  // 往下一層找
  else {
    const nextname = phaseArray[i + 1];
    return await searchMockList(layer[name || phaseArray[1]], phaseArray, nextname);
  }
}

const findAndbuildMcokData = async (url: string) => {
  const ary = url.split('/');
  const category = ary[1];
  ary.splice(0, 2);
  const path = ary.join('/');

  return mockList[category][path]();
}

export const $get = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
}) => {

  const { query, headers, token } = requestInformation(params);

  return useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return fetch(`/${url}${query}`, {
      method: 'GET',
      headers
    })
      .then(async response => {
        const res = await responseProcessing(response);
        // TODO:
        // 成功
        if (!res.errorCode && res.name !== 'Error') {
          success(res);
          const result: GetResponse = {
            Data: res,
            Page: response.headers.get('Page'),
            Rows: response.headers.get('Rows'),
            Total: response.headers.get('Total'),
            Headers: response.headers,
            Timestamp: Date.now()
          }
          return result;

        // 失敗(通用)
        } else {
          if (showMessage) errorAlert(res.errorCode, resCode);
          fail(res);

          const result: GetResponse = {
            Data: [],
            Page: 1,
            Rows: 30,
            Total: 0,
            Headers: {
              get: () => {}
            },
            Timestamp: Date.now()
          }
          return result;
        }
      })

  }, { revalidateOnFocus: false });
}

export const $post = async (
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    if (showMessage) errorAlert(res.errorCode, resCode);
    fail(res);
  }

  setLoading(false);
}

export const $put = async (
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    if (showMessage) errorAlert(res.errorCode, resCode);
    fail(res);
  }

  setLoading(false);
}

export const $patch = async (
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    if (showMessage) errorAlert(res.errorCode, resCode);
    fail(res);
  }

  setLoading(false);
}

export const $delete = async (
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    if (showMessage) errorAlert(res.errorCode, resCode);
    fail(res);
  }

  setLoading(false);
}

export const $api = async (
  method: string,
  {
    url, 
    send = {},
    showMessage = true, 
    resCode = {}, 
    queryHeader = false,
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method,
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (!res.errorCode && res.name !== 'Error') {
    success(res);
  } else {
    if (showMessage) errorAlert(res.errorCode, resCode);
    fail(res);
  }

  setLoading(false);
}

export const $getMock = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
}) => {

  const { query, headers, token } = requestInformation(params);

  return useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return findAndbuildMcokData(url)
      .then(async response => {
        const res = response.body;

        // 成功
        if (!res.errorCode && res.name !== 'Error') {
          success(res);
          const result: GetResponse = {
            Data: res,
            Page: response.headers.get('Page'),
            Rows: response.headers.get('Rows'),
            Total: response.headers.get('Total'),
            Headers: response.headers,
            Timestamp: Date.now()
          }
          return result;
      
        // 失敗(通用)
        } else {
          if (showMessage) errorAlert(res.errorCode, resCode);
          fail(res);
      
          const result: GetResponse = {
            Data: [],
            Page: 1,
            Rows: 30,
            Total: 0,
            Headers: {
              get: () => {}
            },
            Timestamp: Date.now()
          }
          return result;
        }
      });
    
  }, { revalidateOnFocus: false });
}

export const $postMock = async (
  {
    showMessage = true, 
    resCode = {}, 
    success = (data: any) => {}, 
    fail = (data: any) => {}
  }: ApiOptions,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  // 自行輸入errorCode，如為空字串等於成功
  const res = {
    name: "ClientError",
    errorCode: "",
    message: "角色不存在",
    stack: "...",
    timestamp: "2024-08-13T10:21:04.726Z"
  }

  setTimeout(() => {
    if (!res.errorCode && res.name !== 'Error') {
      success({});
    } else {
      if (showMessage) errorAlert(res.errorCode, resCode);
      fail(res);
    }

    setLoading(false);
  }, 400);
}

export const $putMock = $postMock;
export const $patchMock = $postMock;
export const $deleteMock = $postMock;
