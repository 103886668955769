import { Button, Col, Form, message, Row, Select, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupAddPermission } from 'components/advanced.component';
import { LayoutNav, LayoutTabPermission } from 'components/layout.component';
import { ENABLE } from 'constants/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $patch } from 'services';
import { enumToOptions, timeS2L } from 'utils/common';

// 權限管理

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const [form] = useForm();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [param, setParam] = useState<any>({
    page: 1,
    rows: 10,
  });

  const { data, mutate, isValidating } = $get({
    url: 'admin/permission/roles',
    params: param
  });

  const search = (formData: any) => {
    setParam({
      ...param,
      page: 1,
      isEnabled: formData.isEnabled,
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setParam({
      ...param,
      page: 1,
      rows: 10,
    })
  }

  const handleEditState = (item: any) => {
    const id = item.id;
    if (id) {
      $patch({
        url: `admin/permission/roles/${id}/status`,
        send: {
          isEnabled: !item.isEnabled,
        },
        success: () => {
          message.success(i18n.t('editSuccess'));
          mutate();
        },
        fail: () => {
          if (JSON.parse(item.PermissionCode).length === 0) {
            message.error(i18n.t('permissionsNotChecked'));
          }
        },
      })
    }
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabPermission activeKey="1" />

        <Row className="mt-1" gutter={[0, 12]}>
          <Col span={24}>
            <Form form={form} onFinish={search} initialValues={{
              isEnabled: ''
            }}>
              <Row align="middle" gutter={10}>
                <Col className="w-12">
                  <Form.Item name="isEnabled">
                    <Select
                      options={[
                        { value: '', label: i18n.t('allStatus') },
                        ...enumToOptions(ENABLE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Form>
          </Col>
          {$p('permissionRole.addRole') &&
          <Col span={24}>
            <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
          </Col>}
          <Col span={24}>
            <Spin spinning={isValidating}>

              <Table
                size="middle"
                // scroll={{ x: 1500 }}
                dataSource={data?.Data?.map((item: any) => ({ ...item, key: item.id }))}
                columns={[
                  {
                    dataIndex: 'name',
                    title: i18n.t('permissionName'),
                    width: 300,
                    fixed: 'left'
                  },
                  {
                    dataIndex: 'description',
                    title: i18n.t('description'),
                    width: 350,
                    render: (val) => val || '-'
                  },
                  {
                    dataIndex: 'isEnabled',
                    title: i18n.t('status'),
                    align: 'center',
                    width: 100,
                    render: (val, record) => 
                      <Switch checked={val} disabled={!$p('permissionUser.modifyUser')} onChange={v => handleEditState(record)} />
                  },
                  {
                    dataIndex: 'createDatetime',
                    title: i18n.t('createTime'),
                    width: 200,
                    render: (val) => timeS2L(val)
                  },
                  {
                    dataIndex: 'operateUser',
                    title: i18n.t('updater'),
                    width: 140,
                    render: (val) => val?.account || '-'
                  },
                  {
                    dataIndex: 'updateDatetime',
                    title: i18n.t('updateTime'),
                    width: 200,
                    render: (val) => timeS2L(val)
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    fixed: 'right',
                    render: (_, { id }) => (
                      <>
                        {$p('permissionRole.modifyRole') &&
                        <Button className="size-12" type="link" onClick={() => navigate(`/advanced/permission/edit/${id}`)}>
                          {i18n.t('edit')}</Button>}
                        <Button className="size-12" type="link" onClick={() => navigate(`/advanced/account/${id}`)}>
                          {i18n.t('userAccount')}</Button>
                      </>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (Total) => `${i18n.t('overall')} ${Total} ${i18n.t('item')}`,
                  total: data?.Total as any,
                  pageSize: param.rows
                }}
                onChange={p => setParam({ ...param, page: p.current, rows: p.pageSize })}
              />
            </Spin>
          </Col>
        </Row>

        <PopupAddPermission isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} />

      </Content>
    </div>
  );
};

export default PageMain;