import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import type { ActionFunctionArgs } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import PageReportBusiness from "pages/business.page";
import PageReport from "pages/bet-record.page";
import PageReportSettlement from "pages/settlement.page";
import PageReportSettlementSetting from "pages/settlement-setting.page";
import PageReportSms from "pages/sms.page";

async function loader() {
  try {

    return {
      test: {}
    };

  } catch (e) {
    throw new Error("無法取得loader資料");
  };
};

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  switch (formData.get("action")) {
    case "add":
      break;
    case "delete":
      break;

    default:
      break;
  }

  return { ok: true };
}

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const reportModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/report', Component: PageReport },
      { path: '/report/business', Component: PageReportBusiness },
      { path: '/report/settlement', Component: PageReportSettlement },
      { path: '/report/settlement/setting', Component: PageReportSettlementSetting },
      { path: '/report/sms', Component: PageReportSms },
    ],
  },
]