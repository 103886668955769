import { SOCKET_ON } from "constants/socket";
import { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from 'socket.io-client';

export const socketContext = createContext<SocketContext>({});

export const useSocket = () => {
  return useContext(socketContext);
};

export const SocketProvider = ({ children }: {
  children?: any
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [socketId, setSocketId] = useState<string | undefined>("");
  const [serviceMessages, setServiceMessages] = useState<any>({});
  const [privateMessage, setPrivateMessage] = useState<any>({});
  const [notification, setNotification] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [reviewKyc, setReviewKyc] = useState<number>(0);
  const [reviewBankcard, setReviewBankcard] = useState<number>(0);

  useEffect(() => {
    if (!socket) return;
    socket.off();

    // 連線
    socket.on(SOCKET_ON.連線, () => {
      setSocketId(socket.id);
    });
    // 斷線
    socket.on(SOCKET_ON.斷線, () => {
    });
    // 未登入
    socket.on(SOCKET_ON.未登入, () => {
    });
    // 連線失敗
    socket.on(SOCKET_ON.連線錯誤, (msg) => {
    });

  }, [socket, socketId]);

  return (
    <socketContext.Provider value={{
      socket,
      serviceMessages,
      privateMessage,
      reviewKyc,
      reviewBankcard,
      notification,
      sessionId
    }}>
      {children}
    </socketContext.Provider>
  )
}