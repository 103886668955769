import { Select } from "antd";
import { selectFuzzyFilter } from "../utils/common";
import i18n from "../i18n";
import { $get } from "services";

export const SiteCodeAndName = ({ name, form, apiUrl, handleChangeFunction, setSiteId, mode, includeAll = false, disabled = false, className } : {
  name: any;
  form: any;
  apiUrl: string;
  handleChangeFunction?: any;
  setSiteId?: any;
  mode?: any;
  includeAll?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  const { data: siteList } = $get({ url: apiUrl });

  const handleChange = (e: any) => {
    form.setFieldsValue({ [name]: e });

    if (handleChangeFunction) {
      handleChangeFunction(e);
    } else {
      setSiteId?.(e);
    }
  };
  return (
    <Select
      disabled={disabled}
      mode={mode}
      className={className}
      value={form.getFieldValue(name)}
      showSearch
      filterOption={selectFuzzyFilter}
      placeholder={i18n.t('platform')}
      onChange={handleChange}
      options={[
        ...(includeAll ? [{ value: '', label: i18n.t('ALL') }] : []),
        ...(siteList?.Data?.map((item: any) => ({
            value: item.id,
            label: `${item.code} - ${item.name}`,
        })) || []),
      ]}/>
  )
}