import i18n from "i18n";
import { convertedToPercentage01, toFormatNumber } from "utils/common";
import {MEMBER_FREEZE, MEMBER_STATE } from 'constants/state'
import * as common from 'utils/common';
import { SETTLE_CYCLE } from "./promotion";

export const ExcelColumns = {
  // 會員列表
  MemberList: [
    {
      title: i18n.t('memberAccount'),
      dataIndex: "Account",
      excelRender: (text: string, record: any, index: number) => text
    },
    {
      title: i18n.t('promotionCode'),
      dataIndex: "RefCode",
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: "UPMemberAccount",
    },
    {
      title: i18n.t('agent'),
      dataIndex: "AGAccount",
    },
    {
      title: i18n.t('status'),
      dataIndex: 'IsFreeze',
      excelRender: (text: number) => i18n.t(MEMBER_FREEZE[text ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal]),
    },
    {
      title: i18n.t('audit'),
      dataIndex: 'Status',
      excelRender: (text: number) => i18n.t(MEMBER_STATE[text]),
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('createTime'),
      dataIndex: "CreateDate",
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: "RegisterIP",
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: "LastLoginDate",
      render: (val: any) => common.timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: "IP",
    },
    {
      title: i18n.t('lastDepositTime'),
      dataIndex: "LastDepositTime",
      render: (val: any) => common.timeS2L(val)
    },
    {
      dataIndex: 'LastDepositGatewayCode',
      title: i18n.t('depositMethod'),
      render: (val: any) => i18n.t(val) || '-'
    },
    // 會員返水設定值吃上層代理才會顯示
    // (會員返水設定值吃會員vip等級不顯示)
    {
      title: i18n.t('rebate'),
      dataIndex: 'CommissionSettleCycle',
      render: (val: any) => i18n.t(SETTLE_CYCLE[val]),
    },
    {
      title: i18n.t('remark'),
      dataIndex: "RemarkFromAccount",
    },
  ],
  // 交收報表
  ReportSettlement: [
    {
      __style__: { width: 20 },
      dataIndex: 'PlatformName',
      title: `${i18n.t('platformCode')} - ${i18n.t('platformName')}`,
      excelRender: (val: any, { PlatformCode }: any) => `${PlatformCode} - ${val}`
    },
    {
      dataIndex: 'ProviderCode',
      title: i18n.t('gameProvider'),
    },
    {
      dataIndex: 'CategoryCode',
      title: i18n.t('gameCategory'),
      excelRender: (val: any) => i18n.t(val)
    },
    {
      dataIndex: 'Rate',
      title: `${i18n.t('rate')}%`,
      excelRender: (val: any) => convertedToPercentage01(val)
    },
    {
      dataIndex: 'BetAmount',
      title: i18n.t('totalBetAmount'),
      excelRender: (val: any) => toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetAmount',
      title: i18n.t('totalValidBets'),
      excelRender: (val: any) => toFormatNumber(val)
    },
    {
      dataIndex: 'WinLossAmount',
      title: i18n.t('totalGamePnL'),
      excelRender: (val: any) => toFormatNumber(val)
    },
    {
      dataIndex: 'ReceivableAmount',
      title: i18n.t('totalSettlementAmount'),
      excelRender: (val: any) => toFormatNumber(val)
    },
    {
      dataIndex: 'BonusAmount',
      title: i18n.t('totalDiscountAmount'),
      excelRender: (val: any) => toFormatNumber(val)
    },
    {
      dataIndex: 'DonateAmount',
      title: i18n.t('totalDonateAmount'),
      excelRender: (val: any) => toFormatNumber(val)
    }
  ],
}