import { Button, Col, DatePicker, DatePickerProps, Form, Input, InputNumber, Row, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav } from "components/layout.component";
import { CKEditorComp } from "components/promotion.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from "constants/response";
import { ENABLE } from "constants/state";
import dayjs, { Dayjs } from "dayjs";
import i18n from "i18n";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post } from "services";
import { enumToOptions, selectFuzzyFilter, unixLast999, verify } from "utils/common";
import { SiteCodeAndName } from "components/site.component";

// 系統公告新增

const PageMain = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [editorContent, setEditorContent] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(false);

  // 站台列表要抽出
  const { data: siteList } = $get({
    url: 'admin/system-announcement/sites',
  })

  const onFinish = (formData: any) => {
    const beginTime = formData.beginTime.valueOf();
    const endTime = unixLast999(formData.endTime.valueOf());

    $post({
      url: 'admin/system-announcement/announcements',
      send: {
        siteIds: formData.siteIds,
        title: formData.title,
        isEnabled: !!formData.isEnabled,
        sequence: formData.sequence,
        beginTime, 
        endTime,
        contents: editorContent,
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onCancel();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const onCancel = () => {
    form.resetFields();
    navigate('/advanced/announcement');
  }

  const onDateOk: DatePickerProps['onOk'] = (date) => {
    // if (
    //   date.get('D') === dayjs().get('D') &&
    //  (date.get('hours') < dayjs().get('hours') || date.get('minutes') < dayjs().get('minutes'))
    // ) {
    //   const resetDate = dayjs().set('years', date.year()).set('D', dayjs().get('D')).set('hours', dayjs().get('hours')).set('minutes', dayjs().get('minutes'));
    //   form.setFieldValue('publicationTime', dayjs(resetDate));
    // }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          beginTime: dayjs().startOf('day'),
          endTime: dayjs().endOf('day')
        }}>
          <Row gutter={[12, 8]}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Form.Item name="title" label={i18n.t('title')} rules={[
                    { required: true },
                    { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
                  ]}>
                    <Input className="w-18" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>
                      <Button onClick={onCancel}>{i18n.t('cancel')}</Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Item className="w-18" name="siteIds" label={
                <div>
                  <span>{`${i18n.t('platform')}`}</span>
                  <span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                </div>
              } rules={[{ required: true }]}>
                <SiteCodeAndName name="siteIds" form={form} apiUrl={'admin/system-announcement/sites'} mode="multiple" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sequence" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="isEnabled" label={i18n.t('status')} rules={[{ required: true }]} className="w-12">
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={enumToOptions(ENABLE)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="w-12" name="beginTime" label={i18n.t('publicationTime')}
                rules={[{ required: true }]}>
                <DatePicker
                  onChange={() => {
                    form.getFieldValue('beginTime') ? setIsDisabled(false) : setIsDisabled(true)
                    form.setFieldValue('endTime', '');
                  }}
                  disabledDate={(current: Dayjs) => (current < dayjs().startOf('D'))}
                  disabledTime={(date) => {
                    const today = date?.date() !== dayjs().date();
                    const hours = [...Array(24).keys()];
                    const minutes = [...Array(60).keys()];
                    if (today) {
                      return {
                        disabledHours: () => [],
                        disabledMinutes: () => [],
                      }
                    } else {
                      return {
                        disabledHours: () => hours.filter(h => h < dayjs().hour()),
                        disabledMinutes: () => minutes.filter(m => m < dayjs().minute()),
                      }
                    }
                  }}
                  showTime={{ format: 'HH:mm:ss' }}
                  className="w-full"
                  format={"YYYY-MM-DD HH:mm:ss"}
                  onOk={onDateOk}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="w-12" name="endTime" label={i18n.t('endTime')}
                rules={[{ required: true }]} >
                <DatePicker
                  disabled={isDisabled}
                  disabledDate={(current: Dayjs) => (current < dayjs(form.getFieldValue('beginTime')))}
                  disabledTime={(date) => {
                    const today = date?.date() !== dayjs().date();
                    const hours = [...Array(24).keys()];
                    const minutes = [...Array(60).keys()];
                    if (today) {
                      return {
                        disabledHours: () => [],
                        disabledMinutes: () => [],
                      }
                    } else {
                      return {
                        disabledHours: () => hours.filter(h => h < dayjs().hour()),
                        disabledMinutes: () => minutes.filter(m => m < dayjs().minute()),
                      }
                    }
                  }}
                  showTime={{ format: 'HH:mm:ss' }}
                  className="w-full"
                  format={"YYYY-MM-DD HH:mm:ss"}
                  onOk={onDateOk}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="internalContent" label={i18n.t('internalContent')} rules={[{ required: true }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  )
}

export default PageMain;