import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerUnix, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { timeL2S, timeS2L } from 'utils/common';

// 登入記錄

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<{}>({
    startTime: timeL2S(date[0] as string),
    endTime: timeL2S(date[1] as string),
  });

  const { data, mutate, isValidating } = $get({
    url: 'admin/user-login-log/logs',
    params: {
      ...params,
      page: page[0],
      rows: page[1]
    }
  });

  // 權限列表
  const { data: permission } = $get({
    url: 'admin/user-login-log/roles'
  })

  const onFinish = ({ roleId, account }: {
    roleId: number | null;
    account: string | null;
  }) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      startTime: timeL2S(date[0] as string),
      endTime: timeL2S(date[1] as string),
      roleId,
      account
    });
    mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="roleId" className="w-12">
                    <Select
                      // loading={isValidating}
                      optionFilterProp="label"
                      showSearch
                      placeholder={`${i18n.t('backOfficePermissions')}`}
                      options={permission?.Data.map((info: { name: string; id: number }) => ({
                        value: info.id, label: info.name
                      })) || []}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="account" className="w-12">
                    <Input placeholder={`${i18n.t('backOfficeAccount')}`} />
                  </Form.Item>
                </Col>
                <DatePickerUnix width={180} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD'} />
                <Col>
                  <Button htmlType="submit" type="primary">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                pagination={false}
                dataSource={data?.Data?.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    title: i18n.t('loginTime'),
                    dataIndex: 'time',
                    width: 180,
                    render: (val) => timeS2L(val)
                  },
                  {
                    title: i18n.t('backOfficePermissions'),
                    dataIndex: 'user',
                    width: 300,
                    render: (val) => val.roles[0]?.name
                  },
                  {
                    title: i18n.t('backOfficeAccount'),
                    dataIndex: 'user',
                    width: 180,
                    render: (val) => val.account
                  },
                  {
                    title: i18n.t('status'),
                    // 後端註記 沒有登入失敗紀錄
                    width: 120,
                    render: (val) => i18n.t('success')
                  },
                  {
                    title: 'IP',
                    dataIndex: 'ip',
                    width: 180,
                  },
                ]}
              />
              <LayoutPagination total={data?.Total} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;