const main = {

  headers: {
    Page: 1,
    Rows: 30,
    Total: 2,
    get: function(key: string) {
      return this[key];
    }
  } as any,

  "logs": function() {
    const body = [
      {
        "id": 52,
        "ip": "127.0.0.1",
        "time": "1723613054990",
        "createDatetime": "2024-08-14T05:24:15.008Z",
        "user": {
          "id": 3,
          "account": "ozen",
          "isEnabled": true,
          "lastLoginTime": "1723613054946",
          "operateUserId": 0,
          "createDatetime": "2024-08-12T06:05:50.593Z",
          "updateDatetime": "2024-08-14T05:24:14.000Z",
          "roles": [
            {
              "id": 1,
              "name": "全部都開",
              "isEnabled": true,
              "description": "全部都開",
              "operateUserId": 0,
              "createDatetime": "2024-08-12T03:14:33.084Z",
              "updateDatetime": "2024-08-12T03:14:33.084Z"
            }
          ]
        }
      },
      {
        "id": 51,
        "ip": "127.0.0.1",
        "time": "1723600629065",
        "createDatetime": "2024-08-14T01:57:09.076Z",
        "user": {
          "id": 3,
          "account": "ozen",
          "isEnabled": true,
          "lastLoginTime": "1723613054946",
          "operateUserId": 0,
          "createDatetime": "2024-08-12T06:05:50.593Z",
          "updateDatetime": "2024-08-14T05:24:14.000Z",
          "roles": [
            {
              "id": 1,
              "name": "全部都開",
              "isEnabled": true,
              "description": "全部都開",
              "operateUserId": 0,
              "createDatetime": "2024-08-12T03:14:33.084Z",
              "updateDatetime": "2024-08-12T03:14:33.084Z"
            }
          ]
        }
      }
    ]
  
    return {
      body,
      headers: main.headers
    };
  },

}

export default main;