import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember } from 'components/member.component';
import { ENABLE, REBATE_PERIOD } from 'constants/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { convertedToPercentage01 as $g } from 'utils/common';

enum REBATE_SETTINGS {
  default = 1,
  customization = 0,
}

// 會員列表: 返水列表

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [data, setData] = useState<RebateType[]>([]);
  const { id, platformId, account, agId } = useParams();

  // 代理返水(最大值)
  const { data: agentOpp } = $get({
    url: `admin/member/sites/${platformId}/agents/${agId}/operat/params`,
    allow: !!agId && !!platformId
  })
  // 會員返水
  const { data: memberOpp, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/operat/params`,
    allow: !!id && !!platformId
  });
  const commissionmodule = memberOpp?.Headers.get('Commissionmodule');

  // 是否會員返水設定值吃會員vip等級(會員返水設定值吃會員vip等級則不顯示返水欄位)
  const [isRebateDependOnVipLevel, setIsRebateDependOnVipLevel] = useState(false);

  useEffect(() => {
    if (commissionmodule) {
      setIsRebateDependOnVipLevel(commissionmodule === '2');
    }
  }, [commissionmodule]);

  useEffect(() => {
    if (memberOpp && agentOpp) {
      setData([
        {
          key: 1,
          game: i18n.t('EGAMES'),
          name: 'EGamesCommissionRate',
          rebate: memberOpp.Data.EGamesCommissionRate,
          agentRebate: agentOpp.Data.EGamesCommissionRate
        },
        {
          key: 2,
          game: i18n.t('LIVE'),
          name: 'LiveCommissionRate',
          rebate: memberOpp.Data.LiveCommissionRate,
          agentRebate: agentOpp.Data.LiveCommissionRate
        },
        {
          key: 3,
          game: i18n.t('SPORTS'),
          name: 'SportsCommissionRate',
          rebate: memberOpp.Data.SportsCommissionRate,
          agentRebate: agentOpp.Data.SportsCommissionRate
        },
        {
          key: 4,
          game: i18n.t('ESPORTS'),
          name: 'ESportsCommissionRate',
          rebate: memberOpp.Data.ESportsCommissionRate,
          agentRebate: agentOpp.Data.ESportsCommissionRate
        },
        {
          key: 5,
          game: i18n.t('POKER'),
          name: 'PokerCommissionRate',
          rebate: memberOpp.Data.PokerCommissionRate,
          agentRebate: agentOpp.Data.PokerCommissionRate
        },
        {
          key: 6,
          game: i18n.t('FISHING'),
          name: 'FishingCommissionRate',
          rebate: memberOpp.Data.FishingCommissionRate,
          agentRebate: agentOpp.Data.FishingCommissionRate
        },
        {
          key: 7,
          game: i18n.t('COCKFIGHT'),
          name: 'CockfightCommissionRate',
          rebate: memberOpp.Data.CockfightCommissionRate,
          agentRebate: agentOpp.Data.CockfightCommissionRate
        },
        {
          key: 8,
          game: i18n.t('LOTTERY'),
          name: 'LotteryCommissionRate',
          rebate: memberOpp.Data.LotteryCommissionRate,
          agentRebate: agentOpp.Data.LotteryCommissionRate
        },
        {
          key: 9,
          game: i18n.t('SLOT'),
          name: 'SlotCommissionRate',
          rebate: memberOpp.Data.SlotCommissionRate,
          agentRebate: agentOpp.Data.SlotCommissionRate
        }
      ])
    }
    if (memberOpp) {
      form.setFieldsValue({
        Status: memberOpp.Data.Status,
        IsDefault: memberOpp.Data.IsDefault,
        CommissionSettleCycle: memberOpp.Data.CommissionSettleCycle,
        EGamesCommissionRate: $g(memberOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(memberOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(memberOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(memberOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(memberOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(memberOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(memberOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(memberOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(memberOpp.Data.SlotCommissionRate)
      })
    }

  }, [memberOpp, agentOpp])

  return (
    <div id="container">
      <LayoutNav id={id} account={account} agId={agId} />
      <Content>
        <LayoutTabMember activeKey="5" id={id} platformId={platformId} account={account} agId={agId} />

        <Form form={form}>
          <Spin spinning={isValidating}>
            <Row gutter={[12, 12]}>
              {/* 取消確認 */}
              <Col span={24} className="flex-end">
                <Button onClick={() => navigate('/member')}>{i18n.t('close')}</Button>
              </Col>
              {/* 返水週期 */}
              <Col span={24}>
                <Descriptions layout="vertical" column={5} bordered size="small">
                  {/* 返水週期 */}
                  <Descriptions.Item style={{ width: '20%' }} label={
                    <Row align="middle" gutter={[5, 0]}>
                      <Col>
                        <><span className="require">*</span>{i18n.t('rebatePeriod')}</>
                      </Col>
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('sameAsUpperAgent')}>
                          <InfoCircleFilled className="center" />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="CommissionSettleCycle">
                      <Select
                        style={{ width: '100%' }}
                        disabled
                        options={common.enumToOptions(REBATE_PERIOD)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 狀態 */}
                  <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="Status">
                      <Select
                        style={{ width: '100%' }}
                        placeholder={`${i18n.t('pleaseSelect')}`}
                        disabled
                        options={common.enumToOptions(ENABLE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 返水設定 */}
                  {!isRebateDependOnVipLevel &&
                  <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('rebateSettings')}</>}>
                    <Form.Item name="IsDefault">
                      <Select
                        style={{ width: '100%' }}
                        placeholder={`${i18n.t('pleaseSelect')}`}
                        disabled
                        options={common.enumToOptions(REBATE_SETTINGS)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  }
                  {/* 更新人員 */}
                  <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('updateStaff')}</>}>
                    {memberOpp?.Data.Operator || '-'}
                  </Descriptions.Item>
                  {/* 更新時間 */}
                  <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('updateTime')}</>}>
                    {common.timeS2L(memberOpp?.Data.UpdateDate)}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              {/* 批次設定 */}
              <Col span={24} className="text-end">
                <Button type="primary" disabled>
                  {i18n.t('batchSetting')}
                </Button>
              </Col>
              {/* 遊戲大類 */}
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Table
                      bordered
                      size="small"
                      className="pt-1"
                      dataSource={data?.filter((_, i) => i % 2 === 0)}
                      columns={[
                        {
                          dataIndex: 'game',
                          title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                          className: 'color-03',
                          width: '25%',
                          render: (val, record) => `${val} % (max：${$g(record.agentRebate)})`
                        },
                        {
                          title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                          width: '25%',
                          render: (_, record) => (
                            <Form.Item name={record.name}>
                              <Input name={record.name} className="size-12" disabled
                                defaultValue={common.convertedToPercentage02(record.rebate)} />
                            </Form.Item>
                          )
                        },
                      ]}
                      pagination={false}
                    />
                  </Col>
                  <Col span={12}>
                    <Table
                      loading={isValidating}
                      bordered
                      size="small"
                      className="pt-1"
                      dataSource={data && data.filter((_, i) => i % 2 !== 0)}
                      columns={[
                        {
                          dataIndex: 'game',
                          title: <> <span className="require">*</span>{i18n.t('gameCategory')}</>,
                          className: 'color-03',
                          width: '25%',
                          render: (val, record) => `${val} % (max：${$g(record.agentRebate)})`
                        },
                        {
                          title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                          width: '25%',
                          render: (_, record) => (
                            <Form.Item name={record.name}>
                              <Input name={record.name} className="size-12" disabled
                                defaultValue={common.convertedToPercentage02(record.rebate)} />
                            </Form.Item>
                          )
                        },
                      ]}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;