import i18n from "i18n";

export const RESPONSE_CODE = {
  UNAUTHORIZATION: i18n.t('notLogin'),
  ACCOUNT_BLOCKED: i18n.t('accountDisabled'),
  forbidden: i18n.t('permissionDenied'),
  incorrectParams: i18n.t('incorrectParams'),
  accountNotFound: i18n.t('accountDoesNotExist'),
  userNotFound: i18n.t('userNotFound'),
  updateUserSelfPasswordFail: i18n.t('updateUserSelfPasswordFail'),
  siteNotFound:  i18n.t('siteNotFound'),
  siteResponseStatusFail:  i18n.t('siteResponseStatusFail'),
  siteResponseFail:  i18n.t('siteResponseFail'),
  providerCodeNotFound:  i18n.t('providerCodeNotFound'),
  badRequest: i18n.t('incorrectParams'),
  incorrectIpFormat: i18n.t('incorrectIpFormat'),
  systemAnnouncementNotFound:  i18n.t('notFound'),
  incorrectAccountOrPassword:  i18n.t('incorrectAccountOrPassword'),
  accountIsDisabled: i18n.t('accountDisabled'),
  incorrectPassword: i18n.t('INCORRECT_PASSWORD'),
  incorrectPasswordFormat:  i18n.t('incorrectPasswordFormat'),

  incorrectImageExtension:  i18n.t('formatIncorrect'),
  moreThanFileSizeMaxLimit:  i18n.t('imageExceedsSizeLimit'),
};

export const RESPONSE_CODE_GAME = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t('incorrectGame'),
  INCORRECT_NAME: i18n.t('incorrectGameName')
}

export const RESPONSE_CODE_CREATE_ANNOUNCEMENT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_DATE_FORMAT: i18n.t('publicationTimeError')
}