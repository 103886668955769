import { Col, Row, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlatformLayout } from 'components/advanced.component';
import { LayoutNav } from 'components/layout.component';
import React, { useState } from 'react';
import { $get, $put } from "services";
import { useSelector } from "react-redux";
import { RootType } from "../hooks/store.hook";
import i18n from 'i18n';
import useAccount from 'hooks/account.hook';
import { specialProviderName, timeS2L } from "utils/common";
import useSiteById from 'hooks/site-id.hook';

// 站台設置: 遊戲商返水設定

const PageMain: React.FC = () => {
  const [platformOption, setPlatformOption] = useState<string | number | null>(null);

  const siteId = useSelector((state: RootType) => state.Select.siteId);

  // 取得站台列表
  const { data: siteList } = $get({
    url: 'admin/provider-commission/sites'
  })

  const { data: site } = useSiteById(siteId);
  const { permissions: $p } = useAccount();

  // 遊戲商返水設定列表
  const { data: ProviderList, isValidating, mutate } = $get({
    url: `admin/provider-commission/sites/${siteId}/commissions`,
    allow: !!siteId
  });

  // 更新遊戲商返水
  const handleProviderRebateStatus = (code: string, status: boolean) => {
    $put({
      url: `admin/provider-commission/sites/${siteId}/commissions`,
      send: {
        code,
        commissionStatus: status ? 1 : 0
      },
      success: () => {
        mutate();
        message.success(i18n.t('updateSuccess'));
      },
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <PlatformLayout tabKey={'5'} setOption={setPlatformOption} siteList={siteList}/>
        {!!siteId &&
        <Row align="middle" gutter={[10, 16]} className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                size="middle"
                dataSource={ProviderList?.Data.map((item: any) => ({ key: item.Name, ...item }))}
                columns={[
                  {
                    title: i18n.t('gameProvider'),
                    dataIndex: 'Name',
                    width: '25%',
                    render: (val, { Code }) => specialProviderName(val, site.SiteName, Code)
                  },
                  {
                    title: i18n.t('cashbackState'),
                    dataIndex: 'Commission_Status',
                    width: '25%',
                    render: (_, { Code, Commission_Status }) =>
                      <Switch checked={Commission_Status}
                              onChange={status => handleProviderRebateStatus(Code, status)}
                              disabled={!$p('providerCommission.updateProviderCommission')} />
                  },
                  {
                    title: `${i18n.t('platform')}${i18n.t('updater')}`,
                    dataIndex: 'Update_Account',
                    width: '25%'
                  },
                  {
                    title: i18n.t('updateTime'),
                    dataIndex: 'UpdateDate',
                    width: '25%',
                    render: (val: string) => timeS2L(val)
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>}
      </Content>
    </div>
  );
};

export default PageMain;