import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import React from 'react';

// 帳戶紀錄

const PageMain: React.FC = () => {

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        帳戶紀錄
      </Content>
    </div>
  );
};

export default PageMain;